import * as React from 'react'
import { FormTypeSchema, FormVehicleRequest, TopicEnumSchema } from '@api/endpoints/forms'
import {
  FilesFormStep,
  InitialFormStep,
  SummaryStep,
  VehicleFormStep,
  VehicleWizardCommonState,
  VehicleWizardCommonStateActions,
  VehicleWizardDataStep,
  VehicleWizardFilesStep,
  VehicleWizardInitialStep,
  VehicleWizardSummaryStep
} from '@stores/forms'
import { VehicleInitialForm } from '@forms/VehicleInitialForm'
import { VehicleDetailsForm } from '@forms/VehicleDetailsForm'
import { VehiclePhotosForm } from '@forms/VehiclePhotosForm'
import { VehicleFormSuccess } from '@forms/VehicleFormSuccess'
import { useCommissionStore } from './useCommissionStore'
import { PageContent } from '@layout/Page'
import styles from './CommisionWizard.module.css'
import { useTranslation } from 'react-i18next'
import { sendTrackingEvent } from '@api/tracking'

export const CommissionWizard: React.FC = () => {
  const store: VehicleWizardCommonState & VehicleWizardCommonStateActions = useCommissionStore()
  const scrollToTop = (): void => {
    const element = document.getElementById(':r1:')
    if (element) {
      element.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }
  }
  const { t } = useTranslation()
  const renderInitialStep = React.useCallback(
    (state: VehicleWizardInitialStep) => (
      <>
        <h2 className={styles.wizardContainer__subtitle}>{t('pages.commission.subtitle')}</h2>
        <div className={styles.formContainer}>
          <VehicleInitialForm
            wizardStep={state.activeStep}
            formType={FormTypeSchema.enum.COMMISSION}
            defaultValues={undefined}
            onSubmitSuccess={(values) => {
              scrollToTop()
              store.switchStepToData(values)
            }}
            consentChecked={false}
          />
        </div>
      </>
    ),
    [t, store]
  )

  const renderDataStep = React.useCallback(
    (state: VehicleWizardDataStep) => (
      <VehicleDetailsForm
        wizardStep={state.activeStep}
        formId={state.formId}
        formType={FormTypeSchema.enum.COMMISSION}
        phoneNumber={state.phoneNumber}
        defaultValues={{
          mark: state.data.mark,
          model: state.data.model,
          carNumber: state.carNumber,
          mileage: undefined,
          location: undefined,
          requestedPrice: undefined,
          fullName: undefined,
          email: undefined
        }}
        onSubmitSuccess={(values: FormVehicleRequest) => {
          scrollToTop()
          store.switchStepToFiles(values)
        }}
      />
    ),
    [store]
  )

  const renderFilesStep = React.useCallback(
    (state: VehicleWizardFilesStep) => (
      <VehiclePhotosForm
        wizardStep={state.activeStep}
        formType={FormTypeSchema.enum.COMMISSION}
        formId={state.formId}
        model={state.model}
        mark={state.mark}
        plateNumber={state.carNumber}
        onSubmitSuccess={(values) => {
          scrollToTop()
          store.switchStepToSummary(values)
        }}
      />
    ),
    [store]
  )

  const renderSummaryStep = React.useCallback(
    (state: VehicleWizardSummaryStep) => {
      sendTrackingEvent(['gtm', 'gtag', 'fb'], 'generate_lead', {
        lead_source: 'Commission Form',
        content_name: 'Commission Form',
      })

      return (
        <VehicleFormSuccess
          topic={TopicEnumSchema.enum.commission}
          data={state}
          onReset={() => store.reset()}
          showContacts={false}
        />
      )
    },
    [store]
  )

  const stepContent = React.useMemo(() => {
    switch (store.activeStep) {
      case InitialFormStep:
        return renderInitialStep(store)
      case VehicleFormStep:
        return renderDataStep(store)
      case FilesFormStep:
        return renderFilesStep(store)
      case SummaryStep:
        return renderSummaryStep(store)
    }
  }, [store, renderDataStep, renderInitialStep, renderFilesStep, renderSummaryStep])

  return (
    <PageContent>
      <div className={styles.wizardContainer}>{stepContent}</div>
    </PageContent>
  )
}
